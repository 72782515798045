import store from "../../store";

export const authMiddleware = (to, from, next) => {
  const isAuthenticated = store.getters["employees/isAuthenticated"];
  const hasSelectedVenue = store.getters["employees/hasSelectedVenue"];
  const currentAppRoleId = store.getters["employees/currentAppRoleId"];

  if (to.name === "EmployeeLogin" && to.query.magicToken) {
    next();
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (to.meta.requiresVenue && isAuthenticated && !hasSelectedVenue) {
    next("/login");
  } else if (to.path === "/login" && isAuthenticated && hasSelectedVenue) {
    next("/");
  } else if (
    to.meta.requiredRole &&
    Number(currentAppRoleId) !== to.meta.requiredRole
  ) {
    next("/");
  } else {
    next();
  }
};
