import { api } from "../apiFactory";
import store from "@/store";

const getCurrentVenueId = () => store.getters["employees/currentVenueId"];

export const venueApi = {
  getAppPaymentMethods: () => {
    return api.get(`app/payment_methods`);
  },
  getCurrentVenueData: () => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/${currentVenueId}`);
  },
  updateVenue: (data) => {
    const currentVenueId = getCurrentVenueId();
    return api.patch(`venue/${currentVenueId}`, data);
  },
  getReservations: (data) => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/reservation/${currentVenueId}`, { params: data });
  },
  getGroupClasses: (data) => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`venue/venue_group_classes/${currentVenueId}`, {
      params: data,
    });
  },
  updateClasses: (id, data) => api.patch(`venue_group_classes/${id}`, data),
  deleteClasses: (id, options = {}) =>
    api.delete(`venue_group_classes/${id}`, { params: options }),
  getSingleClasses: (classesId) => api.get(`venue_group_classes/${classesId}`),
  addNewVenueSetting: (name, value, venueSportObjectId) => {
    const currentVenueId = getCurrentVenueId();
    return api.post(`/venue_settings`, {
      venue_id: currentVenueId,
      venue_sport_object_id: venueSportObjectId,
      name: name,
      value: value,
    });
  },
  deleteVenueSetting: (venueSettingId) => {
    const currentVenueId = getCurrentVenueId();
    return api.delete(`/venue_settings/${venueSettingId}`, {
      venue_settings_id: venueSettingId,
      venue_id: currentVenueId,
    });
  },
  editVenueSetting: (venueSettingId, venueSportObjectId, name, value) => {
    const currentVenueId = getCurrentVenueId();
    return api.put(`/venue_settings/${venueSettingId}`, {
      venue_id: currentVenueId,
      venue_sport_object_id: venueSportObjectId,
      name: name,
      value: value,
    });
  },
  getVenueSettings: () => {
    const currentVenueId = getCurrentVenueId();
    return api.get(`/venue_settings`, {
      params: {
        venue_id: currentVenueId,
      },
    });
  },
};
