import { createRouter, createWebHistory } from "vue-router";
import { authMiddleware } from "./middleware/auth";

// Import modułów tras
import authRoutes from "./routes/auth.routes";
import calendarRoutes from "./routes/calendar.routes";
import clientsRoutes from "./routes/clients.routes";
import reportsRoutes from "./routes/reports.routes";
import settingsRoutes from "./routes/settings.routes";
import employeesRoutes from "./routes/employees.routes";
import automationRoutes from "./routes/automation.routes";
import testRoutes from "./routes/test.routes";
import errorRoutes from "./routes/errors.routes";
import rootRoutes from "./routes/root.routes";

// Połącz wszystkie trasy
const routes = [
  ...authRoutes,
  ...calendarRoutes,
  ...clientsRoutes,
  ...reportsRoutes,
  ...settingsRoutes,
  ...employeesRoutes,
  ...automationRoutes,
  ...testRoutes,
  ...errorRoutes,
  ...rootRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Zastosuj middleware autoryzacji z pominięciem guide
router.beforeEach((to, from, next) => {
  // Jeśli ścieżka zawiera 'guide' (z lub bez początkowego slash), pomijamy middleware autoryzacji
  if (to.path.includes("guide")) {
    next();
    return;
  }

  // Dla pozostałych ścieżek stosujemy middleware autoryzacji
  authMiddleware(to, from, next);
});

export default router;
