import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  Bars3Icon,
  XMarkIcon,
  CalendarIcon,
  UserGroupIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  UserIcon,
  ChartBarIcon,
  BoltIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/vue/24/outline";

export function useNavbar() {
  const store = useStore();
  const router = useRouter();
  const isCollapsed = ref(false);
  const isMenuOpen = ref(false);
  const selectedVenue = ref("");

  const currentAppRoleId = computed(
    () => store.getters["employees/currentAppRoleId"]
  );

  // Menu items z bezpośrednim przypisaniem komponentów ikon
  const menuItems = computed(() => {
    const items = [
      { name: "Kalendarz", path: "/", icon: CalendarIcon },
      { name: "Klienci", path: "/clients", icon: UserIcon },
      { name: "Pracownicy", path: "/employees", icon: UserGroupIcon },
      { name: "Raporty", path: "/reports", icon: ChartBarIcon },
      { name: "Automatyka", path: "/automation", icon: BoltIcon },
      { name: "Ustawienia", path: "/settings", icon: Cog6ToothIcon },
    ];

    // Dodaj pozycję Root tylko dla użytkowników z rolą 7
    if (Number(currentAppRoleId.value) === 7) {
      items.push({ name: "Root", path: "/root", icon: WrenchScrewdriverIcon });
    }

    return items;
  });

  // Toggle functions
  const toggleSidebar = () => {
    isCollapsed.value = !isCollapsed.value;
  };

  const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
  };

  const closeMenu = () => {
    isMenuOpen.value = false;
  };

  // Auth functions
  const logout = async () => {
    await store.dispatch("employees/logout");
    router.push("/login");
  };

  // Venue selection
  const selectVenue = async () => {
    if (selectedVenue.value) {
      try {
        await store.dispatch("employees/setEmployeeVenue", selectedVenue.value);
        store.dispatch("cloudMessage/showMessage", {
          message: "Ośrodek został zmieniony pomyślnie",
          type: "success",
        });
        router.go();
      } catch (error) {
        store.dispatch("cloudMessage/showMessage", {
          message: "Nie udało się zmienić ośrodka. Spróbuj ponownie.",
          type: "error",
        });
      }
    }
  };

  // Computed properties
  const userName = computed(() => {
    const user = store.getters["employees/currentUser"];
    return user ? `${user.first_name} ${user.last_name}` : "";
  });

  const userEmail = computed(() => {
    const user = store.getters["employees/currentUser"];
    return user ? user.email : "";
  });

  const userInitials = computed(() => {
    const user = store.getters["employees/currentUser"];
    if (user) {
      return `${user.first_name.charAt(0)}${user.last_name.charAt(
        0
      )}`.toUpperCase();
    }
    return "";
  });

  const availableVenues = computed(() => {
    const venues = store.getters["employees/availableVenues"];
    return venues.map((venue) => ({
      ...venue,
      name: `${venue.name} (#${venue.id})`,
    }));
  });

  const currentVenue = computed(
    () => store.getters["employees/currentVenueId"]
  );

  // Route active state
  const isActive = (path) => {
    if (path === "/") {
      return router.currentRoute.value.path === "/";
    }
    return router.currentRoute.value.path.startsWith(path);
  };

  // Initialize selected venue
  selectedVenue.value = currentVenue.value;

  return {
    // State
    isCollapsed,
    isMenuOpen,
    selectedVenue,
    menuItems,

    // Methods
    toggleSidebar,
    toggleMenu,
    closeMenu,
    logout,
    selectVenue,
    isActive,

    // Computed
    userName,
    userEmail,
    userInitials,
    availableVenues,

    // Icons
    icons: {
      Bars3Icon,
      XMarkIcon,
      CalendarIcon,
      UserGroupIcon,
      Cog6ToothIcon,
      ArrowRightOnRectangleIcon,
      UserIcon,
      ChartBarIcon,
      BoltIcon,
    },
  };
}
