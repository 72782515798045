import { defineAsyncComponent } from "vue";

export default [
  {
    path: "/root",
    name: "Root",
    component: defineAsyncComponent(() =>
      import("@/views/rootSettings/RootSettings.vue")
    ),
    meta: {
      requiresAuth: true,
      requiredRole: 7,
    },
  },
];
